import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

import { FundSubscription, FundSubscriptionFilteredFields } from './types';

type GetFundPendingSubscriptionsListColumnDefinitionParams = {
  t: TFunction;
  handleValidateSubscription: (subscriptionId: string, lpId: string) => void;
  customFilters: FundSubscriptionFilteredFields;
  shoudlShowValidationButton: boolean;
};

export const getFundPendingSubscriptionsListColumnDefinition = ({
  t,
  handleValidateSubscription,
  customFilters,
  shoudlShowValidationButton,
}: GetFundPendingSubscriptionsListColumnDefinitionParams): ColDef<FundSubscription>[] => {
  const columns: ColDef<FundSubscription>[] = [
    {
      field: 'lpName',
      headerName: t('pages.fundManager.subscription.table.lpName'),
      chartDataType: 'category',
      filter: true,
      filterParams: customFilters.lpNames,
    },
    {
      headerName: t('pages.fundManager.lps.table.country'),
      colId: 'country',
      field: 'lpCountry',
      type: AgGridColumnTypesEnum.COUNTRY,
    },
    {
      field: 'shareType',
      headerName: t('pages.fundManager.subscription.table.shareName'),
      chartDataType: 'category',
      filter: true,
      filterParams: customFilters.shares,
    },
    {
      field: 'amount',
      headerName: t('pages.fundManager.subscription.table.amount'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'date',
      chartDataType: 'category',
      headerName: t('pages.fundManager.subscription.table.date'),
    },
  ];

  if (shoudlShowValidationButton) {
    columns.push({
      headerName: t('pages.fundManager.subscription.table.validation'),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({ data }: { data: FundSubscription }) => {
        return (
          // TODO: solve group collapsing when validating object
          <ActionButton
            variant={'submit'}
            onClick={() => handleValidateSubscription(data.id, data.lpId)}
          >
            {t('pages.fundManager.subscription.table.actionButton.validate')}
          </ActionButton>
        );
      },
    });
  }

  return columns;
};
